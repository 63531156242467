<template>
  <div class="hero_carousel" :class="heroClass"
    v-if="heroCarouselItems && heroCarouselItems.length > 0">
    <Carousel ref="carousel" :perPage="1" :navigationEnabled="false" :paginationEnabled="false"
      :autoplay="autoPlay" :autoplayTimeout="6000" :speed="1000" :loop="true"
      @pageChange="updateActiveSlide">
      <Slide v-for="(item, index) in heroCarouselItems" :key="index">
        <div class="hero_carousel_item" :class="heroItemClass(item)">
          <div class="hero_carousel_content">
            <div class="hero_carousel_content__box">
              <div class="hero_carousel_content__info">
                <div class="hero_carousel_content__header">
                  {{ item.hero_header || 'Header' }}
                </div>
                <div class="hero_carousel_content__body">
                  {{ item.hero_body || 'Body' }}
                </div>
              </div>
              <div class="hero_carousel_content__cta">
                <a :href="item.link" class="wc-button" :class="heroBtnClass(item)">
                  <span class="label">
                    {{ item.hero_cta || 'More' }}
                    <i class="fa fa-chevron-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div class="hero_carousel_image">
            <img :src="item.image" :alt="item.header"/>
          </div>
        </div>
      </Slide>
    </Carousel>

    <!-- Custom Pagination with Text Header -->
    <div class="hero_carousel_menu" v-if="heroCarouselItems && heroCarouselItems.length > 1">
      <div class="hero_carousel_menu__items">
        <span
          v-for="(item, index) in heroCarouselItems"
          :key="index"
          class="hero_carousel_menu__item"
          :class="{ active: activeSlide === index }"
          @click="goToSlide(index)"
        >
          {{ item.hero_title || 'Title' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'HeroCarousel',
  props: {
    args: String,
  },
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      activeSlide: 0,
      autoPlay: true,
    };
  },
  computed: {
    arguments() {
      return JSON.parse(this.args);
    },
    heroClass() {
      return this.arguments.is_anonymous ? 'hero_carousel--anonymous' : '';
    },
    heroCarouselItems() {
      return this.arguments.carousel_items;
    }
  },
  methods: {
    goToSlide(index) {
      this.activeSlide = index;
      this.autoPlay = false;
      this.$refs.carousel.goToPage(index);
    },
    updateActiveSlide(index) {
      this.activeSlide = index;
    },
    heroItemClass(item) {
      return `hero_carousel_item--${item.hero_background}`;
    },
    heroBtnClass(item) {
      if (item.hero_background === 'dark') {
        return 'wc-button--dark-primary';
      }
      return 'wc-button--primary';
    }
  }
};
</script>
