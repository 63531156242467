import Vue from 'vue';
import Notifications from 'vue-notification';
import heroCarousel from '../components/HeroCarousel.vue';

if (process.env.VUE_APP_DEV_TOOLS) {
  Vue.config.devtools = true;
}
Vue.use(Notifications);

const args = document.getElementById('vheroCarousel').getAttribute('data-args');

new Vue({
  render: (h) => h(heroCarousel, { props: { args } })
}).$mount('#vheroCarousel');
